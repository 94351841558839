
import { React, useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux';

import { addUser } from '../../redux/user.slice';
import store from '../../redux/store'
import Messagesent from '../../components/messagesent'
import { addAddresses } from '../../redux/address.slice';
import * as ga from '../../lib/ga';

import  LoaderImg  from '../loader/loader'

function Login(to) {

    const router = useRouter()
    const user = useSelector((state) => state.user);
    const create = router.query.create ? router.query.create : false

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        set_show_screen(create ? 'register' : 'login')
    }, [create])

    var token = false
    if (user[0] != undefined) {
        token = user[0].token
    }


    const [show_screen, go_show_screen] = useState('login')
    const [password, set_password] = useState('login')
    const [confirm_password, set_confirm_password] = useState('login')
    const [form_submitted, set_form_submitted] = useState(false)
    const [error, set_error_message] = useState(false)
    const [success, set_success_message] = useState(false)

    const set_success = (message) => {
        set_success_message(message)
    }

    const set_error = (message) => {
        set_error_message(message)
    }

    const set_show_screen = (screen) => {
        set_error(false)
        set_success(false)
        go_show_screen(screen)
    }
    // create a temp place for register password values for confirming and checkign validity


    const validatePassword = (event) => {
        set_password(event.target.value)
        var letterNumber = /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        if (event.target.value.match(letterNumber)) {
            // it passes
            event.target.setCustomValidity("")
            return true
        } else {
            // fails
            event.target.setCustomValidity("Password must contain at least 8 characters - letters, numbers and at least one symbol");
            return false
        }
    }

    const validateConfirmPassword = (event) => {
        set_confirm_password(event.target.value)
        if (event.target.value !== password) {
            event.target.setCustomValidity("Password doesnt Match");
            return false
        } else {
            // it passes
            event.target.setCustomValidity("")
            return true
        }

    }

    const submit_and_validate_register_form = (event) => {
        event.preventDefault()
        var chk_status = event.target.checkValidity();
        event.target.reportValidity();
        if (chk_status) {
            register_api_call(event)
        }
    }

    const submit_and_validate_credit_account_form = (event) => {
        event.preventDefault()
        var chk_status = event.target.checkValidity();
        event.target.reportValidity();
        if (chk_status) {
            var result = formsmailer_api_call(event)
        }
        if (result) {
            set_form_submitted(true)
        }
    }


    const set_active_addresses = (user) => {

        const billing = user.addresses.find((item) => item.active_billing == 1) !== undefined ? user.addresses.find((item) => item.active_billing == 1) : user.addresses[0];
        const shipping = user.addresses.find((item) => item.active_delivery == 1) !== undefined ? user.addresses.find((item) => item.active_delivery == 1) : user.addresses[0];

        var data = { billing: billing, shipping: shipping }
        // save to our data store / state tree
        store.dispatch(addAddresses(data))
    }


    const login_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());

        if(form.email && form.password){
        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/login',
            {
                body: JSON.stringify({
                    email: form.email,
                    password: form.password,
                    device_name: 'browser'
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                // dispatch google event
                ga.login_info(data.user);
                // set user
                store.dispatch(addUser(data.user))
                // set user addresses
                set_active_addresses(data.user)

                console.log(data, to)
                // redirect here
                if (to.to == "back") {
                    router.back()
                } else if (to.to == "checkout") {
                    router.push('/checkout/details', undefined, { shallow: true })
                } else {
                    // router.push('/account', undefined, { shallow: true })
                    // nothing?
                    console.log('doesnt match', to.to)
                }
            })
            .catch((error) => {
                set_error(error.error);
            });
        }else{
            alert('Email and password required!')
        }
    }


    const forgot_password_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());
        
        set_error('');   
        setLoading(true);
        set_success('');

        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/forgotpassword',
            {
                body: JSON.stringify({
                    email: form.email,
                    device_name: 'browser'
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                // what to do here?
                setLoading(false);
                set_success('Password Reset Email Sent');
            })
            .catch((error) => {
                setLoading(false);
                if(error.message){
                    set_error(error.message);
                }else{
                    set_error(error.error);   
                }
            });
    }

    const register_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());
        const address = [{
            fname: form.fname,
            lname: form.lname,
            address: form.address,
            address2: form.address2,
            city: form.city,
            company: form.company,
            state: '',
            country: form.country,
            pcode: form.pcode,
            phone: form.phone.replace(/\D/g, ''),
            email: form.email,
            active_billing: 1,
            active_delivery: 0
        }, {
            fname: form.fname,
            lname: form.lname,
            address: form.address,
            address2: form.address2,
            city: form.city,
            company: form.company,
            state: '',
            country: form.country,
            pcode: form.pcode,
            phone: form.phone.replace(/\D/g, ''),
            email: form.email,
            active_billing: 0,
            active_delivery: 1
        }];

        // send the email as the email and the name at the remote end
        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/register',
            {
                body: JSON.stringify({
                    name: form.fname + " " + form.lname,
                    email: form.email,
                    password: form.password,
                    business: form.business,
                    device_name: 'browser',
                    promo: form.promoConfirm,
                    address: JSON.stringify(address)
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                // set user
                store.dispatch(addUser(data.user))
                // set user address
                set_active_addresses(data.user)

                // check if they are excluded from ordering
                if (data[0]?.excluded_from_ordering) {
                    if (to.to == "back") {
                        // if they want to go 'back', let them
                        router.back()
                    } else {
                        // if they want to go anywhere else, take them to homepage
                        router.push('/', undefined, { shallow: true })
                    }
                } else {
                    // redirect here
                    if (to.to == "back") {
                        router.back()
                    } else if (to.to == "checkout") {
                        router.push('/checkout/details', undefined, { shallow: true })
                    } else {
                        // router.push('/account', undefined, { shallow: true })
                        // nothing?
                        console.log('doesnt match', to.to)
                    }
                }
            })
            .catch((error) => {
                set_error(error.error);
            });
    }

    const formsmailer_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());

        const body_text = (form) => {
            var text = ""
            for (var key of Object.keys(form)) {
                text += (key + " - " + form[key]) + "\n"
            }
            return text
        }

        // send the email as the email and the name at the remote end
        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/formsmailer',
            {
                body: JSON.stringify({
                    body: body_text(form),
                    subject: "Credit Account Application",
                    reply_to: form.email,
                    device_name: 'browser'
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                return true
                // set_success('Email Sent');
            })
            .catch((error) => {
                set_error(error.error);
            });

    }


    return (
        <>
        {loading && <LoaderImg />}
            <div className="lg:w-1/3 w-full">
                <div className="bg-white shadow rounded mb-10">

                    {
                        (show_screen == 'forgot_password') ? (
                            <>
                                <nav className="tabs grid grid-cols-1">
                                    <button onClick={() => set_show_screen('forgot_password')} className="tab font-bold active text-gray-900 py-4 block focus:outline-none bg-white lg:text-lg text-base rounded-tl">
                                        Reset Password
                                    </button>
                                </nav>
                            </>
                        ) : (
                            <>
                                <nav className="tabs grid grid-cols-2">
                                    {
                                        (show_screen == 'login') ? (
                                            <>
                                                <button onClick={() => set_show_screen('login')} className="tab font-bold active text-gray-900 py-4 block focus:outline-none bg-white lg:text-lg text-base rounded-tl">
                                                    Sign In
                                                </button>
                                                <button onClick={() => set_show_screen('register')} className="tab font-bold text-gray-600 py-4 block focus:outline-none lg:text-lg text-base bg-gray-200 rounded-tr hover:text-gray-900 hover:bg-gray-100 transition duration-150 ease-in-out">
                                                    New Customer
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button onClick={() => set_show_screen('login')} className="tab font-bold text-gray-600 py-4 block focus:outline-none lg:text-lg text-base bg-gray-200 rounded-tl hover:text-gray-900 hover:bg-gray-100 transition duration-150 ease-in-out">
                                                    Sign In
                                                </button>
                                                <button onClick={() => set_show_screen('register')} className="tab font-bold active text-gray-900 py-4 block focus:outline-none bg-white lg:text-lg text-base rounded-tr">
                                                    New Customer
                                                </button>
                                            </>
                                        )}
                                </nav>
                            </>
                        )}

                    <div id="panels" className="p-8">
                        <div className="panel-1 tab-content active space-y-4">
                            {
                                (show_screen == 'login') ? (
                                    <>
                                        <form method="post" onSubmit={login_api_call}>
                                            <label className="field-label mb-4">
                                                <span className="label-text">E-Mail Address</span>
                                                <input type="email" name="email" className="input-field w-full" required />
                                            </label>
                                            <label className="field-label mb-4">
                                                <span className="label-text">Password</span>
                                                <input type="password" name="password" className="input-field w-full" required />
                                            </label>
                                            {/* <label className="flex items-center mb-6">
                                                <input className="h-5 w-5 form-checkbox mr-2 rounded text-brand-primary focus:ring-brand-tertiary hover:border-brand-secondary" type="checkbox" />
                                                <span className="checkbox-label-text leading-none">Stay signed in?</span>
                                            </label> */}

                                            <button className="btn btn-primary btn-lg btn-block">Continue</button>

                                            <div className="text-center mt-4">
                                                <a onClick={() => set_show_screen('forgot_password')} className="text-link">Forgotten Password?</a>
                                            </div>
                                        </form>
                                    </>
                                ) : ('')
                            }

                            {
                                (show_screen == 'forgot_password') ? (
                                    <>
                                        <form method="post" onSubmit={forgot_password_api_call}>
                                            <label className="field-label mb-4">
                                                <span className="label-text">E-Mail Address</span>
                                                <input type="email" name="email" required className="input-field w-full" />
                                            </label>

                                            <button className="btn btn-primary btn-lg btn-block">Continue</button>

                                            <div className="text-center mt-4">
                                                <a onClick={() => set_show_screen('login')} className="text-link text-sm">Back to Login</a>
                                            </div>
                                        </form>
                                    </>
                                ) : ('')
                            }

                            {
                                (show_screen == 'register') ? (
                                    <>
                                        {/* <div className="flex items-center flex-col justify-center py-5"><i className="icon icon-new-customer w-32 h-32"></i></div>
                                <button className="bg-brand-primary rounded w-full flex items-center px-4 py-3 text-white text-base justify-center font-semibold">Create An Account</button> */}
                                        <div className="">
                                            <h3 className="mb-4">Create An Account</h3>
                                            <p className="text-gray-700 text-base font-normal mb-4">Create an account below to access exclusive offers and content, save your payment details for easy transactions, and to leave product reviews as part of a community of fellow customers.</p>
                                            {/* <p className="text-gray-700 text-base font-normal mb-4">Looking to set up a credit account? — <a onClick={() => set_show_screen('credit_account')} className="text-link">Request an account here.</a></p> */}
                                            <p className="text-gray-700 text-base font-normal mb-6"><small>Fields marked with an asterix (*) are required.</small></p>
                                            <form method="post" onSubmit={(event) => submit_and_validate_register_form(event)}>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Company Name</span>
                                                        <input type="text" name="company" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Customer Type <span className="text-red-600">*</span></span>
                                                        <select className="dropdown-field" name="business">
                                                            <option>Consumer/Homeowner</option>
                                                            <option>Facilities &amp; Property Maintenance</option>
                                                            <option>Installer/Tradesman</option>
                                                            <option>Merchant</option>
                                                            <option>Other</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">First Name <span className="text-red-600">*</span></span>
                                                        <input type="" required name="fname" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Last Name <span className="text-red-600">*</span></span>
                                                        <input type="" required name="lname" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">E-Mail Address <span className="text-red-600">*</span></span>
                                                        <input type="email" required name="email" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Address Line 1 <span className="text-red-600">*</span></span>
                                                        <input type="" required name="address" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Address Line 2</span>
                                                        <input type="" name="address2" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Town / City <span className="text-red-600">*</span></span>
                                                        <input type="" required name="city" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Postcode <span className="text-red-600">*</span></span>
                                                        <input type="" required name="pcode" className="input-field" />
                                                    </label>
                                                </div>
                                                {/* <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Country <span className="text-red-600">*</span></span>
                                                        <select className="input-field w-full" name="country">
                                                            <option>Select Country</option>
                                                            <option value="AF">Afghanistan</option>
                                                            <option value="AX">Aland Islands</option>
                                                            <option value="AL">Albania</option>
                                                            <option value="DZ">Algeria</option>
                                                            <option value="AS">American Samoa</option>
                                                            <option value="AD">Andorra</option>
                                                            <option value="AO">Angola</option>
                                                            <option value="AI">Anguilla</option>
                                                            <option value="AQ">Antarctica</option>
                                                            <option value="AG">Antigua and Barbuda</option>
                                                            <option value="AR">Argentina</option>
                                                            <option value="AM">Armenia</option>
                                                            <option value="AW">Aruba</option>
                                                            <option value="AU">Australia</option>
                                                            <option value="AT">Austria</option>
                                                            <option value="AZ">Azerbaijan</option>
                                                            <option value="BS">Bahamas</option>
                                                            <option value="BH">Bahrain</option>
                                                            <option value="BD">Bangladesh</option>
                                                            <option value="BB">Barbados</option>
                                                            <option value="BY">Belarus</option>
                                                            <option value="BE">Belgium</option>
                                                            <option value="BZ">Belize</option>
                                                            <option value="BJ">Benin</option>
                                                            <option value="BM">Bermuda</option>
                                                            <option value="BT">Bhutan</option>
                                                            <option value="BO">Bolivia</option>
                                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                            <option value="BA">Bosnia and Herzegovina</option>
                                                            <option value="BW">Botswana</option>
                                                            <option value="BV">Bouvet Island</option>
                                                            <option value="BR">Brazil</option>
                                                            <option value="IO">British Indian Ocean Territory</option>
                                                            <option value="BN">Brunei Darussalam</option>
                                                            <option value="BG">Bulgaria</option>
                                                            <option value="BF">Burkina Faso</option>
                                                            <option value="BI">Burundi</option>
                                                            <option value="KH">Cambodia</option>
                                                            <option value="CM">Cameroon</option>
                                                            <option value="CA">Canada</option>
                                                            <option value="CV">Cape Verde</option>
                                                            <option value="KY">Cayman Islands</option>
                                                            <option value="CF">Central African Republic</option>
                                                            <option value="TD">Chad</option>
                                                            <option value="CL">Chile</option>
                                                            <option value="CN">China</option>
                                                            <option value="CX">Christmas Island</option>
                                                            <option value="CC">Cocos (Keeling) Islands</option>
                                                            <option value="CO">Colombia</option>
                                                            <option value="KM">Comoros</option>
                                                            <option value="CG">Congo</option>
                                                            <option value="CD">Congo, Democratic Republic of the Congo</option>
                                                            <option value="CK">Cook Islands</option>
                                                            <option value="CR">Costa Rica</option>
                                                            <option value="CI">Cote D&#39;Ivoire</option>
                                                            <option value="HR">Croatia</option>
                                                            <option value="CU">Cuba</option>
                                                            <option value="CW">Curacao</option>
                                                            <option value="CY">Cyprus</option>
                                                            <option value="CZ">Czech Republic</option>
                                                            <option value="DK">Denmark</option>
                                                            <option value="DJ">Djibouti</option>
                                                            <option value="DM">Dominica</option>
                                                            <option value="DO">Dominican Republic</option>
                                                            <option value="EC">Ecuador</option>
                                                            <option value="EG">Egypt</option>
                                                            <option value="SV">El Salvador</option>
                                                            <option value="GQ">Equatorial Guinea</option>
                                                            <option value="ER">Eritrea</option>
                                                            <option value="EE">Estonia</option>
                                                            <option value="ET">Ethiopia</option>
                                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                                            <option value="FO">Faroe Islands</option>
                                                            <option value="FJ">Fiji</option>
                                                            <option value="FI">Finland</option>
                                                            <option value="FR">France</option>
                                                            <option value="GF">French Guiana</option>
                                                            <option value="PF">French Polynesia</option>
                                                            <option value="TF">French Southern Territories</option>
                                                            <option value="GA">Gabon</option>
                                                            <option value="GM">Gambia</option>
                                                            <option value="GE">Georgia</option>
                                                            <option value="DE">Germany</option>
                                                            <option value="GH">Ghana</option>
                                                            <option value="GI">Gibraltar</option>
                                                            <option value="GR">Greece</option>
                                                            <option value="GL">Greenland</option>
                                                            <option value="GD">Grenada</option>
                                                            <option value="GP">Guadeloupe</option>
                                                            <option value="GU">Guam</option>
                                                            <option value="GT">Guatemala</option>
                                                            <option value="GG">Guernsey</option>
                                                            <option value="GN">Guinea</option>
                                                            <option value="GW">Guinea-Bissau</option>
                                                            <option value="GY">Guyana</option>
                                                            <option value="HT">Haiti</option>
                                                            <option value="HM">Heard Island and Mcdonald Islands</option>
                                                            <option value="VA">Holy See (Vatican City State)</option>
                                                            <option value="HN">Honduras</option>
                                                            <option value="HK">Hong Kong</option>
                                                            <option value="HU">Hungary</option>
                                                            <option value="IS">Iceland</option>
                                                            <option value="IN">India</option>
                                                            <option value="ID">Indonesia</option>
                                                            <option value="IR">Iran, Islamic Republic of</option>
                                                            <option value="IQ">Iraq</option>
                                                            <option value="IE">Ireland</option>
                                                            <option value="IM">Isle of Man</option>
                                                            <option value="IL">Israel</option>
                                                            <option value="IT">Italy</option>
                                                            <option value="JM">Jamaica</option>
                                                            <option value="JP">Japan</option>
                                                            <option value="JE">Jersey</option>
                                                            <option value="JO">Jordan</option>
                                                            <option value="KZ">Kazakhstan</option>
                                                            <option value="KE">Kenya</option>
                                                            <option value="KI">Kiribati</option>
                                                            <option value="KP">Korea, Democratic People&#39;s Republic of</option>
                                                            <option value="KR">Korea, Republic of</option>
                                                            <option value="XK">Kosovo</option>
                                                            <option value="KW">Kuwait</option>
                                                            <option value="KG">Kyrgyzstan</option>
                                                            <option value="LA">Lao People&#39;s Democratic Republic</option>
                                                            <option value="LV">Latvia</option>
                                                            <option value="LB">Lebanon</option>
                                                            <option value="LS">Lesotho</option>
                                                            <option value="LR">Liberia</option>
                                                            <option value="LY">Libyan Arab Jamahiriya</option>
                                                            <option value="LI">Liechtenstein</option>
                                                            <option value="LT">Lithuania</option>
                                                            <option value="LU">Luxembourg</option>
                                                            <option value="MO">Macao</option>
                                                            <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                                            <option value="MG">Madagascar</option>
                                                            <option value="MW">Malawi</option>
                                                            <option value="MY">Malaysia</option>
                                                            <option value="MV">Maldives</option>
                                                            <option value="ML">Mali</option>
                                                            <option value="MT">Malta</option>
                                                            <option value="MH">Marshall Islands</option>
                                                            <option value="MQ">Martinique</option>
                                                            <option value="MR">Mauritania</option>
                                                            <option value="MU">Mauritius</option>
                                                            <option value="YT">Mayotte</option>
                                                            <option value="MX">Mexico</option>
                                                            <option value="FM">Micronesia, Federated States of</option>
                                                            <option value="MD">Moldova, Republic of</option>
                                                            <option value="MC">Monaco</option>
                                                            <option value="MN">Mongolia</option>
                                                            <option value="ME">Montenegro</option>
                                                            <option value="MS">Montserrat</option>
                                                            <option value="MA">Morocco</option>
                                                            <option value="MZ">Mozambique</option>
                                                            <option value="MM">Myanmar</option>
                                                            <option value="NA">Namibia</option>
                                                            <option value="NR">Nauru</option>
                                                            <option value="NP">Nepal</option>
                                                            <option value="NL">Netherlands</option>
                                                            <option value="AN">Netherlands Antilles</option>
                                                            <option value="NC">New Caledonia</option>
                                                            <option value="NZ">New Zealand</option>
                                                            <option value="NI">Nicaragua</option>
                                                            <option value="NE">Niger</option>
                                                            <option value="NG">Nigeria</option>
                                                            <option value="NU">Niue</option>
                                                            <option value="NF">Norfolk Island</option>
                                                            <option value="MP">Northern Mariana Islands</option>
                                                            <option value="NO">Norway</option>
                                                            <option value="OM">Oman</option>
                                                            <option value="PK">Pakistan</option>
                                                            <option value="PW">Palau</option>
                                                            <option value="PS">Palestinian Territory, Occupied</option>
                                                            <option value="PA">Panama</option>
                                                            <option value="PG">Papua New Guinea</option>
                                                            <option value="PY">Paraguay</option>
                                                            <option value="PE">Peru</option>
                                                            <option value="PH">Philippines</option>
                                                            <option value="PN">Pitcairn</option>
                                                            <option value="PL">Poland</option>
                                                            <option value="PT">Portugal</option>
                                                            <option value="PR">Puerto Rico</option>
                                                            <option value="QA">Qatar</option>
                                                            <option value="RE">Reunion</option>
                                                            <option value="RO">Romania</option>
                                                            <option value="RU">Russian Federation</option>
                                                            <option value="RW">Rwanda</option>
                                                            <option value="BL">Saint Barthelemy</option>
                                                            <option value="SH">Saint Helena</option>
                                                            <option value="KN">Saint Kitts and Nevis</option>
                                                            <option value="LC">Saint Lucia</option>
                                                            <option value="MF">Saint Martin</option>
                                                            <option value="PM">Saint Pierre and Miquelon</option>
                                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                                            <option value="WS">Samoa</option>
                                                            <option value="SM">San Marino</option>
                                                            <option value="ST">Sao Tome and Principe</option>
                                                            <option value="SA">Saudi Arabia</option>
                                                            <option value="SN">Senegal</option>
                                                            <option value="RS">Serbia</option>
                                                            <option value="CS">Serbia and Montenegro</option>
                                                            <option value="SC">Seychelles</option>
                                                            <option value="SL">Sierra Leone</option>
                                                            <option value="SG">Singapore</option>
                                                            <option value="SX">Sint Maarten</option>
                                                            <option value="SK">Slovakia</option>
                                                            <option value="SI">Slovenia</option>
                                                            <option value="SB">Solomon Islands</option>
                                                            <option value="SO">Somalia</option>
                                                            <option value="ZA">South Africa</option>
                                                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                            <option value="SS">South Sudan</option>
                                                            <option value="ES">Spain</option>
                                                            <option value="LK">Sri Lanka</option>
                                                            <option value="SD">Sudan</option>
                                                            <option value="SR">Suriname</option>
                                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                                            <option value="SZ">Swaziland</option>
                                                            <option value="SE">Sweden</option>
                                                            <option value="CH">Switzerland</option>
                                                            <option value="SY">Syrian Arab Republic</option>
                                                            <option value="TW">Taiwan, Province of China</option>
                                                            <option value="TJ">Tajikistan</option>
                                                            <option value="TZ">Tanzania, United Republic of</option>
                                                            <option value="TH">Thailand</option>
                                                            <option value="TL">Timor-Leste</option>
                                                            <option value="TG">Togo</option>
                                                            <option value="TK">Tokelau</option>
                                                            <option value="TO">Tonga</option>
                                                            <option value="TT">Trinidad and Tobago</option>
                                                            <option value="TN">Tunisia</option>
                                                            <option value="TR">Turkey</option>
                                                            <option value="TM">Turkmenistan</option>
                                                            <option value="TC">Turks and Caicos Islands</option>
                                                            <option value="TV">Tuvalu</option>
                                                            <option value="UG">Uganda</option>
                                                            <option value="UA">Ukraine</option>
                                                            <option value="AE">United Arab Emirates</option>
                                                            <option selected="true" value="GB">United Kingdom</option>
                                                            <option value="US">United States</option>
                                                            <option value="UM">United States Minor Outlying Islands</option>
                                                            <option value="UY">Uruguay</option>
                                                            <option value="UZ">Uzbekistan</option>
                                                            <option value="VU">Vanuatu</option>
                                                            <option value="VE">Venezuela</option>
                                                            <option value="VN">Viet Nam</option>
                                                            <option value="VG">Virgin Islands, British</option>
                                                            <option value="VI">Virgin Islands, U.s.</option>
                                                            <option value="WF">Wallis and Futuna</option>
                                                            <option value="EH">Western Sahara</option>
                                                            <option value="YE">Yemen</option>
                                                            <option value="ZM">Zambia</option>
                                                            <option value="ZW">Zimbabwe</option>
                                                        </select>
                                                    </label>
                                                </div> */}
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Phone Number <span className="text-red-600">*</span></span>
                                                        <input type="" required name="phone" className="input-field" />
                                                    </label>
                                                </div>
                                                <label className="field-label mb-4">
                                                    <span className="label-text">Password <span className="text-red-600">*</span></span>
                                                    <input onChange={(event) => validatePassword(event)} type="password" name="password" required className="input-field w-full" />
                                                    <small className="text-gray-500">Must contain numbers, letters and at least one symbol</small>
                                                </label>
                                                <label className="field-label mb-4">
                                                    <span className="label-text">Confirm Password <span className="text-red-600">*</span></span>
                                                    <input onChange={(event) => validateConfirmPassword(event)} type="password" name="confirm_password" required className="input-field w-full" />
                                                </label>
                                                <label className="flex mb-6 items-center">
                                                    <input className="h-5 w-5 form-checkbox mr-2 rounded text-brand-primary focus:ring-brand-tertiary hover:border-brand-secondary" required name="termsConfirm" type="checkbox" />
                                                    <span className="checkbox-label-text leading-none">I agree to the <Link href="/terms"><a className="text-link">terms and conditions</a></Link> <span className="text-red-600">*</span></span>
                                                </label>
                                                <label className="flex mb-6">
                                                    <input className="h-5 w-5 form-checkbox mr-2 rounded text-brand-primary focus:ring-brand-tertiary hover:border-brand-secondary" name="promoConfirm" type="checkbox" />
                                                    <span className="checkbox-label-text leading-none">Please keep me informed of any special offers or other promotional activities</span>
                                                </label>
                                                <button className="btn btn-primary btn-lg btn-block">Create Account</button>
                                            </form>
                                        </div>
                                    </>
                                ) : ('')
                            }
                            {
                                (show_screen == 'credit_account') ? (
                                    <>
                                        {(form_submitted) ? (
                                            <Messagesent></Messagesent>
                                        ) : (
                                            <div className="">
                                                <h3 className="mb-4">Request Credit Account</h3>
                                                <p className="text-gray-600 text-base font-normal mb-4">Please use the form below to request a credit account. We manually check all credit requests which means it may take up to 1-2 weeks for an account to be approved.</p>
                                                <p className="text-gray-600 text-base font-normal mb-4">This form will be checked by a human and can typically take 1-2 weeks to approve.</p>
                                                <p className="text-gray-600 text-base font-normal mb-4">If you are looking for a standard account where you can pay instantly through a credit/debit card, then please <a onClick={() => set_show_screen('register')} className="text-brand-primary text-base underline font-semibold">click here.</a>.</p>
                                                <p className="text-gray-600 text-base font-normal"><small>Fields marked with an asterix (*) are required.</small></p>

                                                <form method="post" onSubmit={(event) => submit_and_validate_credit_account_form(event)}>

                                                    <div className="mb-4">
                                                        <label className="field-label">Company Name <span className="text-red-600">*</span></label>
                                                        <input type="" name="company" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Customer Type <span className="text-red-600">*</span></label>
                                                        <select name="business_type" className="appearance-none block w-full py-2 px-3 border border-gray-300 font-normal rounded focus:outline-none text-base text-gray-500">
                                                            <option>Consumer/Homeowner</option>
                                                            <option>Facilities & Property Maintenance</option>
                                                            <option>Installer/Tradesman</option>
                                                            <option>Merchant</option>
                                                            <option>Other</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">First Name <span className="text-red-600">*</span></label>
                                                        <input type="" name="fname" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Last Name <span className="text-red-600">*</span></label>
                                                        <input type="" name="lname" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">E-Mail Address <span className="text-red-600">*</span></label>
                                                        <input type="email" name="email" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Address Line 1 <span className="text-red-600">*</span></label>
                                                        <input type="" name="address" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Address Line 2</label>
                                                        <input type="" name="address2" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Town / City <span className="text-red-600">*</span></label>
                                                        <input type="" name="city" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Postcode <span className="text-red-600">*</span></label>
                                                        <input type="" name="pcode" className="input-field" />
                                                    </div>
                                                    {/* <div className="mb-4">
                                                        <label className="field-label">
                                                            <span className="label-text">Country <span className="text-red-600">*</span></span>
                                                            <select className="input-field w-full" name="country">
                                                                <option>Select Country</option>
                                                                <option value="AF">Afghanistan</option>
                                                                <option value="AX">Aland Islands</option>
                                                                <option value="AL">Albania</option>
                                                                <option value="DZ">Algeria</option>
                                                                <option value="AS">American Samoa</option>
                                                                <option value="AD">Andorra</option>
                                                                <option value="AO">Angola</option>
                                                                <option value="AI">Anguilla</option>
                                                                <option value="AQ">Antarctica</option>
                                                                <option value="AG">Antigua and Barbuda</option>
                                                                <option value="AR">Argentina</option>
                                                                <option value="AM">Armenia</option>
                                                                <option value="AW">Aruba</option>
                                                                <option value="AU">Australia</option>
                                                                <option value="AT">Austria</option>
                                                                <option value="AZ">Azerbaijan</option>
                                                                <option value="BS">Bahamas</option>
                                                                <option value="BH">Bahrain</option>
                                                                <option value="BD">Bangladesh</option>
                                                                <option value="BB">Barbados</option>
                                                                <option value="BY">Belarus</option>
                                                                <option value="BE">Belgium</option>
                                                                <option value="BZ">Belize</option>
                                                                <option value="BJ">Benin</option>
                                                                <option value="BM">Bermuda</option>
                                                                <option value="BT">Bhutan</option>
                                                                <option value="BO">Bolivia</option>
                                                                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                                <option value="BA">Bosnia and Herzegovina</option>
                                                                <option value="BW">Botswana</option>
                                                                <option value="BV">Bouvet Island</option>
                                                                <option value="BR">Brazil</option>
                                                                <option value="IO">British Indian Ocean Territory</option>
                                                                <option value="BN">Brunei Darussalam</option>
                                                                <option value="BG">Bulgaria</option>
                                                                <option value="BF">Burkina Faso</option>
                                                                <option value="BI">Burundi</option>
                                                                <option value="KH">Cambodia</option>
                                                                <option value="CM">Cameroon</option>
                                                                <option value="CA">Canada</option>
                                                                <option value="CV">Cape Verde</option>
                                                                <option value="KY">Cayman Islands</option>
                                                                <option value="CF">Central African Republic</option>
                                                                <option value="TD">Chad</option>
                                                                <option value="CL">Chile</option>
                                                                <option value="CN">China</option>
                                                                <option value="CX">Christmas Island</option>
                                                                <option value="CC">Cocos (Keeling) Islands</option>
                                                                <option value="CO">Colombia</option>
                                                                <option value="KM">Comoros</option>
                                                                <option value="CG">Congo</option>
                                                                <option value="CD">Congo, Democratic Republic of the Congo</option>
                                                                <option value="CK">Cook Islands</option>
                                                                <option value="CR">Costa Rica</option>
                                                                <option value="CI">Cote D&#39;Ivoire</option>
                                                                <option value="HR">Croatia</option>
                                                                <option value="CU">Cuba</option>
                                                                <option value="CW">Curacao</option>
                                                                <option value="CY">Cyprus</option>
                                                                <option value="CZ">Czech Republic</option>
                                                                <option value="DK">Denmark</option>
                                                                <option value="DJ">Djibouti</option>
                                                                <option value="DM">Dominica</option>
                                                                <option value="DO">Dominican Republic</option>
                                                                <option value="EC">Ecuador</option>
                                                                <option value="EG">Egypt</option>
                                                                <option value="SV">El Salvador</option>
                                                                <option value="GQ">Equatorial Guinea</option>
                                                                <option value="ER">Eritrea</option>
                                                                <option value="EE">Estonia</option>
                                                                <option value="ET">Ethiopia</option>
                                                                <option value="FK">Falkland Islands (Malvinas)</option>
                                                                <option value="FO">Faroe Islands</option>
                                                                <option value="FJ">Fiji</option>
                                                                <option value="FI">Finland</option>
                                                                <option value="FR">France</option>
                                                                <option value="GF">French Guiana</option>
                                                                <option value="PF">French Polynesia</option>
                                                                <option value="TF">French Southern Territories</option>
                                                                <option value="GA">Gabon</option>
                                                                <option value="GM">Gambia</option>
                                                                <option value="GE">Georgia</option>
                                                                <option value="DE">Germany</option>
                                                                <option value="GH">Ghana</option>
                                                                <option value="GI">Gibraltar</option>
                                                                <option value="GR">Greece</option>
                                                                <option value="GL">Greenland</option>
                                                                <option value="GD">Grenada</option>
                                                                <option value="GP">Guadeloupe</option>
                                                                <option value="GU">Guam</option>
                                                                <option value="GT">Guatemala</option>
                                                                <option value="GG">Guernsey</option>
                                                                <option value="GN">Guinea</option>
                                                                <option value="GW">Guinea-Bissau</option>
                                                                <option value="GY">Guyana</option>
                                                                <option value="HT">Haiti</option>
                                                                <option value="HM">Heard Island and Mcdonald Islands</option>
                                                                <option value="VA">Holy See (Vatican City State)</option>
                                                                <option value="HN">Honduras</option>
                                                                <option value="HK">Hong Kong</option>
                                                                <option value="HU">Hungary</option>
                                                                <option value="IS">Iceland</option>
                                                                <option value="IN">India</option>
                                                                <option value="ID">Indonesia</option>
                                                                <option value="IR">Iran, Islamic Republic of</option>
                                                                <option value="IQ">Iraq</option>
                                                                <option value="IE">Ireland</option>
                                                                <option value="IM">Isle of Man</option>
                                                                <option value="IL">Israel</option>
                                                                <option value="IT">Italy</option>
                                                                <option value="JM">Jamaica</option>
                                                                <option value="JP">Japan</option>
                                                                <option value="JE">Jersey</option>
                                                                <option value="JO">Jordan</option>
                                                                <option value="KZ">Kazakhstan</option>
                                                                <option value="KE">Kenya</option>
                                                                <option value="KI">Kiribati</option>
                                                                <option value="KP">Korea, Democratic People&#39;s Republic of</option>
                                                                <option value="KR">Korea, Republic of</option>
                                                                <option value="XK">Kosovo</option>
                                                                <option value="KW">Kuwait</option>
                                                                <option value="KG">Kyrgyzstan</option>
                                                                <option value="LA">Lao People&#39;s Democratic Republic</option>
                                                                <option value="LV">Latvia</option>
                                                                <option value="LB">Lebanon</option>
                                                                <option value="LS">Lesotho</option>
                                                                <option value="LR">Liberia</option>
                                                                <option value="LY">Libyan Arab Jamahiriya</option>
                                                                <option value="LI">Liechtenstein</option>
                                                                <option value="LT">Lithuania</option>
                                                                <option value="LU">Luxembourg</option>
                                                                <option value="MO">Macao</option>
                                                                <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                                                <option value="MG">Madagascar</option>
                                                                <option value="MW">Malawi</option>
                                                                <option value="MY">Malaysia</option>
                                                                <option value="MV">Maldives</option>
                                                                <option value="ML">Mali</option>
                                                                <option value="MT">Malta</option>
                                                                <option value="MH">Marshall Islands</option>
                                                                <option value="MQ">Martinique</option>
                                                                <option value="MR">Mauritania</option>
                                                                <option value="MU">Mauritius</option>
                                                                <option value="YT">Mayotte</option>
                                                                <option value="MX">Mexico</option>
                                                                <option value="FM">Micronesia, Federated States of</option>
                                                                <option value="MD">Moldova, Republic of</option>
                                                                <option value="MC">Monaco</option>
                                                                <option value="MN">Mongolia</option>
                                                                <option value="ME">Montenegro</option>
                                                                <option value="MS">Montserrat</option>
                                                                <option value="MA">Morocco</option>
                                                                <option value="MZ">Mozambique</option>
                                                                <option value="MM">Myanmar</option>
                                                                <option value="NA">Namibia</option>
                                                                <option value="NR">Nauru</option>
                                                                <option value="NP">Nepal</option>
                                                                <option value="NL">Netherlands</option>
                                                                <option value="AN">Netherlands Antilles</option>
                                                                <option value="NC">New Caledonia</option>
                                                                <option value="NZ">New Zealand</option>
                                                                <option value="NI">Nicaragua</option>
                                                                <option value="NE">Niger</option>
                                                                <option value="NG">Nigeria</option>
                                                                <option value="NU">Niue</option>
                                                                <option value="NF">Norfolk Island</option>
                                                                <option value="MP">Northern Mariana Islands</option>
                                                                <option value="NO">Norway</option>
                                                                <option value="OM">Oman</option>
                                                                <option value="PK">Pakistan</option>
                                                                <option value="PW">Palau</option>
                                                                <option value="PS">Palestinian Territory, Occupied</option>
                                                                <option value="PA">Panama</option>
                                                                <option value="PG">Papua New Guinea</option>
                                                                <option value="PY">Paraguay</option>
                                                                <option value="PE">Peru</option>
                                                                <option value="PH">Philippines</option>
                                                                <option value="PN">Pitcairn</option>
                                                                <option value="PL">Poland</option>
                                                                <option value="PT">Portugal</option>
                                                                <option value="PR">Puerto Rico</option>
                                                                <option value="QA">Qatar</option>
                                                                <option value="RE">Reunion</option>
                                                                <option value="RO">Romania</option>
                                                                <option value="RU">Russian Federation</option>
                                                                <option value="RW">Rwanda</option>
                                                                <option value="BL">Saint Barthelemy</option>
                                                                <option value="SH">Saint Helena</option>
                                                                <option value="KN">Saint Kitts and Nevis</option>
                                                                <option value="LC">Saint Lucia</option>
                                                                <option value="MF">Saint Martin</option>
                                                                <option value="PM">Saint Pierre and Miquelon</option>
                                                                <option value="VC">Saint Vincent and the Grenadines</option>
                                                                <option value="WS">Samoa</option>
                                                                <option value="SM">San Marino</option>
                                                                <option value="ST">Sao Tome and Principe</option>
                                                                <option value="SA">Saudi Arabia</option>
                                                                <option value="SN">Senegal</option>
                                                                <option value="RS">Serbia</option>
                                                                <option value="CS">Serbia and Montenegro</option>
                                                                <option value="SC">Seychelles</option>
                                                                <option value="SL">Sierra Leone</option>
                                                                <option value="SG">Singapore</option>
                                                                <option value="SX">Sint Maarten</option>
                                                                <option value="SK">Slovakia</option>
                                                                <option value="SI">Slovenia</option>
                                                                <option value="SB">Solomon Islands</option>
                                                                <option value="SO">Somalia</option>
                                                                <option value="ZA">South Africa</option>
                                                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                                <option value="SS">South Sudan</option>
                                                                <option value="ES">Spain</option>
                                                                <option value="LK">Sri Lanka</option>
                                                                <option value="SD">Sudan</option>
                                                                <option value="SR">Suriname</option>
                                                                <option value="SJ">Svalbard and Jan Mayen</option>
                                                                <option value="SZ">Swaziland</option>
                                                                <option value="SE">Sweden</option>
                                                                <option value="CH">Switzerland</option>
                                                                <option value="SY">Syrian Arab Republic</option>
                                                                <option value="TW">Taiwan, Province of China</option>
                                                                <option value="TJ">Tajikistan</option>
                                                                <option value="TZ">Tanzania, United Republic of</option>
                                                                <option value="TH">Thailand</option>
                                                                <option value="TL">Timor-Leste</option>
                                                                <option value="TG">Togo</option>
                                                                <option value="TK">Tokelau</option>
                                                                <option value="TO">Tonga</option>
                                                                <option value="TT">Trinidad and Tobago</option>
                                                                <option value="TN">Tunisia</option>
                                                                <option value="TR">Turkey</option>
                                                                <option value="TM">Turkmenistan</option>
                                                                <option value="TC">Turks and Caicos Islands</option>
                                                                <option value="TV">Tuvalu</option>
                                                                <option value="UG">Uganda</option>
                                                                <option value="UA">Ukraine</option>
                                                                <option value="AE">United Arab Emirates</option>
                                                                <option selected="true" value="GB">United Kingdom</option>
                                                                <option value="US">United States</option>
                                                                <option value="UM">United States Minor Outlying Islands</option>
                                                                <option value="UY">Uruguay</option>
                                                                <option value="UZ">Uzbekistan</option>
                                                                <option value="VU">Vanuatu</option>
                                                                <option value="VE">Venezuela</option>
                                                                <option value="VN">Viet Nam</option>
                                                                <option value="VG">Virgin Islands, British</option>
                                                                <option value="VI">Virgin Islands, U.s.</option>
                                                                <option value="WF">Wallis and Futuna</option>
                                                                <option value="EH">Western Sahara</option>
                                                                <option value="YE">Yemen</option>
                                                                <option value="ZM">Zambia</option>
                                                                <option value="ZW">Zimbabwe</option>
                                                            </select>
                                                        </label>
                                                    </div> */}
                                                    <div className="mb-4">
                                                        <label className="field-label">Phone Number <span className="text-red-600">*</span></label>
                                                        <input type="" name="phone" className="input-field" />
                                                    </div>
                                                    <label className="flex mb-6">
                                                        <input className="h-5 w-5 form-checkbox mr-2 rounded text-brand-primary focus:ring-brand-tertiary hover:border-brand-secondary" type="checkbox" name="termsConfirm" />
                                                        <span className="checkbox-label-text leading-none">Please keep me informed of any special offers or other promotional activities</span>
                                                    </label>
                                                    <label className="flex mb-6">
                                                        <input className="h-5 w-5 form-checkbox mr-2 rounded text-brand-primary focus:ring-brand-tertiary hover:border-brand-secondary" type="checkbox" name="promoConfirm" />
                                                        <span className="checkbox-label-text leading-none">I agree to the <Link href="/terms"><a href="" className="text-brand-primary text-base underline font-semibold">terms and conditions</a></Link>.</span>
                                                    </label>
                                                    <button className="bg-brand-primary rounded w-full flex items-center px-4 py-3 text-white text-base justify-center font-semibold">Request Credit Account</button>
                                                </form>
                                            </div>
                                        )}
                                    </>
                                ) : ('')
                            }
                            {(error) ? (<p className="bg-rose-50 border border-rose-300 text-rose-900 rounded p-1 leading-none mt-1 text-sm">{error}</p>) : ('')}
                            {(success) ? (<p className="bg-emerald-50 border border-emerald-300 text-emerald-900 rounded p-1 leading-none mt-1 text-sm">{success}</p>) : ('')}
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <div className="mb-6">
                        {
                            /* No longer needed */
                            /*(show_screen == 'login') ? (
                                <>
                                    <span className="text-gray-700 text-base font-normal">Don&apos;t have an account?</span> <a onClick={() => set_show_screen('register')} className="text-link">Create account</a>
                                </>
                            ) : (
                                <>
                                    <span className="text-gray-700 text-base font-normal">Already have an account?</span> <a onClick={() => set_show_screen('login')} className="text-link">Log In</a>
                                </>
                            )*/
                        }
                        {
                            (router.pathname != '/checkout') ? (
                                <>
                                    <div className="mt-8">
                                        <Link href="/privacy"><a href="" className="text-link text-sm">Privacy Policy</a></Link>
                                        <span className="inline-block text-gray-600 text-sm mx-2">•</span>
                                        <Link href="/terms"><a href="" className="text-link text-sm">Terms and Conditions</a></Link>
                                    </div>
                                </>
                            ) : ('')
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
