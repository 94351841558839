import React from 'react'
import Link from 'next/link'

function Messagesent({ item }) {
    return (


        <div className="lg:w-1/2 bg-white rounded-lg shadow p-5 lg:p-8 mx-auto text-center">
            <div className="mb-2"><i className="fas fa-check-circle text-green-600 text-6xl"></i></div>
            <h2 className="h2 mb-6">Message Sent</h2>
            <p className="text-gray-900 mb-2">Your message has been sent. Someone will review your message and get back to you as soon as possible. </p>
            <p className="text-gray-900 mb-4">Remember you can follow us on social media and stay up to date with all the latest news and offers!</p>
            <Link href="/">
                <a className="btn btn-lg btn-primary mx-auto">
                    Continue
                </a>
            </Link>
        </div>

    );
}

export default Messagesent;